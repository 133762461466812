import * as React from "react"

import { MDXRenderer } from "gatsby-plugin-mdx"

import InlineTableOfContents from "../components/TableOfContents/InlineTableOfContents"
import Layout from "../components/root/Layout"

const DocsTemplate = ({ data }) => {
  const post = data

  console.log(post)
  return (
    <Layout>
      <article className="prose dark:prose-invert max-w-6xl w-full py-8 text-lg antialiased">
        <div className="flex flex-col gap-y-4 mb-16">
          <h1 className="text-4xl font-bold text-primary">
            {post.frontmatter.title}
          </h1>
          {post?.tableOfContents?.items && (
            <InlineTableOfContents items={post.tableOfContents.items} />
          )}
        </div>
        <MDXRenderer>{post.body}</MDXRenderer>
      </article>
    </Layout>
  )
}

export default DocsTemplate
