import * as React from "react"

import { graphql } from "gatsby"

import DocsTemplate from "../../templates/docs-template"

const MdxPage = ({ data }) => {
  const pageData = data.mdx
  const category = pageData.frontmatter?.category

  const templates = {
    docs: <DocsTemplate data={pageData} />,
  }

  return <>{templates[category] ? templates[category] : null}</>
}

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      body
      tableOfContents
      frontmatter {
        title
        category
      }
    }
  }
`

export default MdxPage
