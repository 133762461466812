import * as React from "react"
import { useThemeContext } from "../../context/theme-context"

const InlineTableOfContents = ({ items }) => {
  const { theme } = useThemeContext()
  return (
    <div className="flex flex-col">
      <h4 className="font-bold text-3xl mb-6">Table of Contents</h4>
      <ol className="list-decimal list-inside gap-y-2">
        {items.map(item => {
          return (
            <li key={item.url}>
              <a
                href={item.url}
                className={`text-blue-500 font-semibold underline`}
              >
                {item.title}
              </a>
            </li>
          )
        })}
      </ol>
    </div>
  )
}

export default InlineTableOfContents
